/* Reset default margin and padding for the body */
body {
  margin: 0;
  padding: 0;
}

/* Hide non-printable elements */
.non-printable {
  display: none;
}

/* Style for printable content */
.printable {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for absolute elements */
  padding-top: 200px; /* Adjust based on header height */
  padding-bottom: 100px; /* Adjust based on footer height */
}

/* Page break setup */
.page-break {
  page-break-after: always;
}

/* Category section styling */
.category-break {
  margin-top: 20px;
}

.category-title {
  
  color: white;
  padding: 10px;
  @apply bg-[#17A2B8] bg-opacity-90 w-6/12 rounded-md shadow-md font-bold; 
}

/* Header and Footer images */
.header, .footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header {
  top: 0;
}

.footer {
  bottom: 0;
}

.footer img, .header img {
  width: 100%;
  height: auto;
}

/* Adjust content position to avoid overlap with fixed header */
.table-container {
  position: relative;
  top: 0;
}
